<template>
<div class="main">
  <div class="main_right">
    <VxeBasicTable ref="xGrid" service="/xifang/questionnaireDetail" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    selectRow: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      currNode: {},
      formData: {},
      alarmSelectLists: [],
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "code",
            title: "编号",
            width: 100,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入编号"
              }
            }
          },
          {
            field: "title",
            title: "标题",
            minWidth: 200,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入标题"
              }
            }
          },
          {
            field: "type",
            title: "填写方式",
            width: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "selectType",
              props: {
                placeholder: "请选择填写方式"
              }
            }
          },
          {
            field: "orderCode",
            title: "排序",
            width: 100,
            editRender: {
              name: "$input",
              props: {
                maxlength: 3,
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "修改",
                  name: 'edit',
                  eventName: "queryEvent",
                  events: {
                    click: (data) => {
                      this.alarmSelectLists = [];
                      let { items } = this.formOptions;
                      let { formData } = this.$refs.xGrid;
                      let item = {};
                      if (data.type == 2) {
                        item = this.$XEUtils.filterTree(items, item => item.field === "alarmRadioValue")[0];
                        this.$set(formData, "alarmRadioValue", this.$dictionary.serialNumber[data.alarmValue]);
                      } else if (data.type == 3) {
                        item = this.$XEUtils.filterTree(items, item => item.field === "alarmSelectValue")[0];
                        this.$set(formData, "alarmSelectValue", data.alarmValue);
                      } else {
                        this.$set(formData, "alarmInputValue", data.alarmValue);
                      }
                      if (data.optionList && data.optionList.length) {
                        data.optionList.forEach(item => {
                          this.alarmSelectLists.push({
                            value: item.value,
                            label: this.$dictionary.serialNumber[item.value]
                          });
                        });
                        this.$set(item.itemRender, "options", this.alarmSelectLists);
                      }
                    }
                  }
                },
                {
                  status: "danger",
                  label: "删除",
                  name: 'delete',
                  eventName: "removeEvent"
                }
              ]
            }
          }
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 1000,
        height: 600,
        mask: false,
        titleWidth: 100,
        items: [{
            field: "code",
            title: "编号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "integer",
                placeholder: "请输入编号"
              }
            }
          },
          {
            field: "type",
            title: "填写方式",
            span: 12,
            visibleMethod: ({ $form, data, property }) => {
              if (data[property] == 2 || data[property] == 3) {
                this.$Tools.enabledEvent($form.items, ["questionnaireAlarmType"], true);
              } else {
                this.$Tools.enabledEvent($form.items, ["questionnaireAlarmType"], false);
              }
              return true
            },
            itemRender: {
              name: "$select",
              optionTypeCode: "selectType",
              props: {
                placeholder: "请选择填写方式"
              },
              events: {
                change: ({ $form, data, property }) => {
                  data["alarmValue"] = null;
                  data["alarmInputValue"] = null;
                  data["alarmRadioValue"] = null;
                  data["alarmSelectValue"] = null;
                  this.alarmSelectLists = [];
                  let item = this.$XEUtils.find($form.items, item => item.field === "alarmSelectValue");
                  if (data[property] == 3) {
                    data["questionnaireAlarmType"] = "4";
                    this.$set(item.itemRender, "options", []);
                    this.$Tools.enabledEvent($form.items, ["questionnaireAlarmType"], true);
                  } else if (data[property] == 2) {
                    data["questionnaireAlarmType"] = "2";
                    this.$set(item.itemRender, "options", []);
                    this.$Tools.enabledEvent($form.items, ["questionnaireAlarmType"], true);
                  } else {
                    data["questionnaireAlarmType"] = "1";
                    data.placeholder = `请输入`
                    data.optionList = [];
                    this.$Tools.enabledEvent($form.items, ["questionnaireAlarmType"], false);
                  }
                }
              }
            }
          },
          {
            field: "title",
            title: "标题",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 32,
                placeholder: "请输入标题"
              }
            }
          },
          {
            field: "placeholder",
            title: "提示语",
            span: 12,
            visibleMethod({ data }) {
              if (data.type == '1' || data.type == '4') {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入提示语"
              }
            }
          },
          {
            field: "optionList",
            title: "选项值",
            span: 24,
            visibleMethod({ data }) {
              if (data.type == '2' || data.type == '3') {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$VxeFormGeneration",
              props: {
                placeholder: ""
              },
              events: {
                change: (params, list) => {
                  this.alarmSelectLists = [];
                  let { $form, data } = params;
                  data["alarmValue"] = "";
                  data["alarmInputValue"] = "";
                  data["alarmRadioValue"] = "";
                  data["alarmSelectValue"] = "";
                  let item = {};
                  if (data.type == 2) {
                    item = this.$XEUtils.filterTree($form.items, item => item.field === "alarmRadioValue")[0]
                  }
                  if (data.type == 3) {
                    item = this.$XEUtils.filterTree($form.items, item => item.field === "alarmSelectValue")[0]
                  }
                  list.forEach((item, index) => {
                    if (item.label) {
                      this.alarmSelectLists.push({
                        value: index,
                        label: this.$dictionary.serialNumber[index]
                      });
                    }
                  });
                  this.$set(item.itemRender, "options", this.alarmSelectLists);
                }
              }
            }
          },
          {
            field: "orderCode",
            title: "排序",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 3,
                placeholder: "请输入排序号",
              },
            },
          },
          {
            span: 24,
            visibleMethod({ data }) {
              if (data.type) {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "告警值设置",
                position: "left"
              }
            },
          },
          {
            field: "questionnaireAlarmType",
            title: "告警类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "questionnaireAlarmType",
              props: {
                clearable: true,
                placeholder: "请选择告警类型"
              },
              events: {
                change: ({ $form, data, property }) => {
                  data["alarmInputValue"] = "";
                  data["alarmRadioValue"] = "";
                  data["alarmSelectValue"] = "";
                  let alarmInputValue = this.$XEUtils.find($form.items, item => item.field === "alarmInputValue");
                  if (data[property] != 4) {
                    alarmInputValue.itemRender.props.type = "number";
                  } else {
                    alarmInputValue.itemRender.props.type = "";
                  }
                },
                clear: ({ $form, data, property }) => {
                  let alarmInputValue = this.$XEUtils.find($form.items, item => item.field === "alarmInputValue");
                  alarmInputValue.itemRender.props.type = "";
                  data["alarmValue"] = "";
                  data["alarmInputValue"] = "";
                  data["alarmRadioValue"] = "";
                  data["alarmSelectValue"] = "";
                }
              }
            }
          },
          {
            field: "alarmInputValue",
            title: "告警值",
            span: 12,
            visibleMethod({ data, property }) {
              if (data["type"] == '1' || data["type"] == '4') {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "number",
                maxlength: 12,
                placeholder: "请输入告警值"
              },
              events: {
                change: ({ data, property }) => {
                  data["alarmValue"] = data[property]
                }
              }
            }
          },
          {
            field: "alarmRadioValue",
            title: "告警选项",
            span: 12,
            visibleMethod({ data }) {
              if (data["type"] == '2') {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$select",
              props: {
                placeholder: "请选择告警项"
              },
              events: {
                change: ({ data, property }) => {
                  data["alarmValue"] = data[property]
                }
              }
            }
          },
          {
            field: "alarmSelectValue",
            title: "告警选项",
            span: 12,
            visibleMethod({ data }) {
              if (data["type"] == '3') {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$select",
              props: {
                multiple: true,
                placeholder: "请选择告警项"
              },
              events: {
                change: ({ data, property }) => {
                  data["alarmValue"] = data[property];
                }
              }
            }
          },
          {
            span: 24,
            visibleMethod({ data }) {
              if (data.type) {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "预览",
                position: "left"
              }
            },
          },
          {
            span: 24,
            visibleMethod({ data }) {
              if (data.type) {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$VxeFormPreview",
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          title: [{ required: true, message: "请输入标题" }],
          code: [{ required: true, message: "请输入编号" }],
          type: [{ required: true, message: "请选择填写方式" }],
          orderCode: [{ required: true, message: "请输入排序号" }],
        },
        // 新增功能字段初始化
        data: {
          questionnaireId: this.selectRow.id,
          title: "",
          code: '',
          type: "1",
          placeholder: "请输入",
          optionList: [],
          alarmRadioValue: null,
          alarmSelectValue: null,
          questionnaireAlarmType: "",
          orderCode: "1"
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "code",
            title: "编号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入编号",
              },
            },
          },
          {
            field: "title",
            title: "标题",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入标题",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          questionnaireId: this.selectRow.id,
          code: '',
          title: '',
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "getListService",
      "removeEventService",
      "submitEventService",
      "queryEventService",
      "importAllEventService",
      "exportAllEventService",
    ]),
    // 编辑事件
    queryEvent(row) {
      this.loading = true;
      this.queryEventService({
          service: this.serviceUrl,
          params: row,
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.formData = data;
            this.$refs.xModal.open();
          }
          this.resetEvent();
        })
        .catch((err) => {
          this.resetEvent();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
