<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" title="添加问题" show-zoom width="1000" height="600">
    <template v-slot>
      <questionnaireDetail :selectRow="selectRow" />
    </template>
  </vxe-modal>
</div>
</template>

<script>
import questionnaireDetail from "./questionnaireDetail.vue";
export default {
  components: {
    questionnaireDetail
  },
  data() {
    return {
      selectRow: {},
      loading: false,
      currNode: {},
      formData: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1001') {
                    if (this.$refs.xGrid.tableData.length) {
                      this.$message.error('只能有一条流调数据')
                    } else {
                      let { data } = this.formOptions;
                      data["parentId"] = this.currNode.id;
                      this.searchOptions.data['parentId'] = this.currNode.id;
                      this.$refs.xGrid.insertEvent();
                    }
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "name",
            title: "流调名称",
            minWidth: 160,
            align: "left",
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入流调名称"
              }
            }
          },
          {
            fixed: "right",
            title: "操作",
            width: 220,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "addQuestion",
                  status: "primary",
                  label: "添加问题",
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.$refs.xModal.open();
                    }
                  }
                },
                {
                  name: "edit",
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent"
                },
                {
                  name: "delete",
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent"
                }
              ]
            }
          }
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
          },
          {
            field: "name",
            title: "流调名称",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入流调名称"
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属上级" }],
          name: [{ required: true, message: "请输入标题" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          name: "",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "流调名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入流调名称",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {},
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data['parentId'] = node.id;
      this.searchOptions.data['parentId'] = node.id;
      this.$refs.xGrid.getLists();
    }
  },
};
</script>

<style lang="scss" scoped></style>
